<template>
    <div>
        <div v-for="(link, index) in columnData" :key="index">
            <template v-if="typeof link.to !== 'undefined'">
                <router-link :to="link.to">{{link.label}}</router-link>
            </template>
            <template v-else>
                {{link}}
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: Array,
    },
}
</script>